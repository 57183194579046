import { ContentUriType } from "outbound-api-client/lib/models";
import { Colours } from "../style/colours";
import { Theme } from "../style/theme";
import BidSupportTile from '../assets/images/tile-bidsupport.jpg';
import BidSupportMobileTile from '../assets/images/tile-bidsupport-mobile.jpg';
import CompatibilityTile from '../assets/images/tile-compatibility.jpg';
import CompatibilityMobileTile from '../assets/images/tile-compatibility-mobile.jpg';
import LegacyTile from '../assets/images/canonfocuspep/tile-offers.png';
import LegacyMobileTile from '../assets/images/canonfocuspep/tile-offers-mobile.png';
import { string, number, date } from 'yup';
import { ConfigProps, DealRegFormFieldDataType, LayoutType } from "./config.service.types";
import { DealSummaryMode } from "../modules/OpportunityManagement/DealSummary.types";

export const CanonFocusPepConfig: ConfigProps = {
  appUri: `${process.env.REACT_APP_URL}`,
  apiUri: `${process.env.REACT_APP_API_URL}`,
  clientId: "canonfocuspep",
  clientSecret: "GI4X9391eIcMLAozSUxtPSMR6x5Qhz2cyJH15BLBqRz+NdH+CQWVZACUfn901/6/QMpVsxQakhBDAQrpLRoGQw==",
  applicationTitle: "Canon Focus",
  campaignIdentifier: "",
  layoutType: LayoutType.Canon,
  theme: Theme,
  colours: Colours,
  learnProps: {
    groupCategories: true
  },
  footerProps: {
    smallPrint: "&copy; Outbound Field Marketing Services",
    helpUrl: "mailto:support@channel360.net?subject=Canon Focus Help",
    helpText: "Help",
    privacyUrl: `${process.env.REACT_APP_URL}/article/Canon_Focus_Hub_Privacy_Policy_EN_GB`,
    privacyText: "Privacy Information",
    termsUrl: `${process.env.REACT_APP_URL}/article/Terms_of_Use_EN_GB`,
    termsText: "Terms",
    cookiePolicyUrl: "https://outbound.blob.core.windows.net/cookies/cookiepolicy.html",
    cookiePolicyText: "Cookies"
  },
  enableProps: {
    groupByCategory: true,
    searchEnabled: true,
    downloadIcons: false,
    tiles: [
      {
        title: "Current Offers",
        summary: "Offers for you and your customers",
        url: "https://canonfocuspep.blob.core.windows.net/public/Offers/Monthly_Offers_2024_11.pdf",
        target: "_blank",
        thumnbails:
          [
            {
              identifier: 'Legacy',
              uri: LegacyTile,
              type: ContentUriType.ThumbnailWide
            },
            {
              identifier: 'Legacy-Mobile',
              uri: LegacyMobileTile,
              type: ContentUriType.ThumbnailTall
            },
          ]
      },
      {
        title: "Bid Support",
        summary: "Additional price support for sales.",
        url: "/bidsupport",
        target: "",
        thumnbails: [
          {
            identifier: 'Bid-Support',
            uri: BidSupportTile,
            type: ContentUriType.ThumbnailWide
          },
          {
            identifier: 'Bid-Support-Mobile',
            uri: BidSupportMobileTile,
            type: ContentUriType.ThumbnailTall
          },
        ]
      },
      {
        title: "Accessories",
        summary: "Check Canon accessory compatibility.",
        url: "https://canonfocuspep.blob.core.windows.net/public/15515_Canon%20Print%20Consumables%20Chart.pdf",
        target: "_blank",
        thumnbails: [
          {
            identifier: 'Compatibility',
            uri: CompatibilityTile,
            type: ContentUriType.ThumbnailWide
          },
          {
            identifier: 'Compatibility-Mobile',
            uri: CompatibilityMobileTile,
            type: ContentUriType.ThumbnailTall
          },
        ]
      }
    ]
  },
  campaignProps: {
    termsButtonText: "SUBMIT",
    termsButtonSubmittingText: "SUBMITTING",
    prizeSection: {
      show: false,
      title: null,
      intro: null,
      dividerImageUrl: null
    },
    dealSummarySections: [
      {
        title: "MY POINTS SUMMARY",
        logoUrl: null,
        oem: "",
        playbookUrl:"",//Don't use this for Flex and Deal playbooks - it's in the DB
        borderColour: "#bd422a",
        mode: DealSummaryMode.MyPoints
      }
    ]
  },
  navigationProps: {
    showSalesTools: false,
    showQuiz: false,
    showLeaderboard: true
  },
  opportunityManagementProps: {
    vendors: [],
    dealRegForm: [{
      campaignIdentifier: null,
      dealRegFormSchema: {
        manufacturer: string().required('You must select a vendor'),
        status: string().required('You must select a status'),
        productType: string().required('You must enter a product type'),
        opportunityDetails: string().required('You must enter the opportunity details'),
        estimatedSalesValue: number().positive("Amount must be greater than zero").required('You must enter a value').typeError('Amount must be a number only'),
        estimatedCloseDate: date().required('You must enter an estimated close date').nullable(true),
      },
      dealRegForm: [
        { name: "status", label: "Status", dataType: DealRegFormFieldDataType.Choice, values: [{ identifier: "OPEN", label: "Open", value: "OPEN" }, { identifier: "WON", label: "Won", value: "WON" }, { identifier: "LOST", label: "Lost", value: "LOST" }] },
        { name: "customerName", label: "Customer Name", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "opportunityDetails", label: "Invoice Number *", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "productType", label: "Product Details *", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "estimatedSalesValue", label: "Quantity *", dataType: DealRegFormFieldDataType.Number, values: [] },
        { name: "estimatedCloseDate", label: "(Estimated) Invoice Date *", dataType: DealRegFormFieldDataType.Date, values: []}
      ],
      dealRegFormInitialValues: {
        status: 'OPEN',
        manufacturer: 'NONE',
        customerName: '',
        productType: '',
        opportunityDetails: '',
        estimatedSalesValue: 0,
        estimatedCloseDate: new Date()
      },
      dealsEditable: false
    },
    {
      campaignIdentifier: "dealreward",
      dealRegFormSchema: {
        manufacturer: string().required('You must select a vendor'),
        customerName: string().required('You must enter a customer'),
        status: string().required('You must select a status'),
        productType: string().required('You must enter a product type'),
        estimatedSalesValue: number().positive("Amount must be greater than zero").required('You must enter a value').typeError('Amount must be a number only'),
        estimatedCloseDate: date().required('You must enter an estimated close date').nullable(true),
      },
      dealRegForm: [
        { name: "status", label: "Status", dataType: DealRegFormFieldDataType.Choice, values: [{ identifier: "OPEN", label: "Open", value: "OPEN", editOnly: false }, { identifier: "WON", label: "Won", value: "WON", editOnly: true }, { identifier: "LOST", label: "Lost", value: "LOST", editOnly: true }] },
        { name: "customerName", label: "End Customer Name *", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "opportunityDetails", label: "Quote/Invoice Number", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "productType", label: "Product Details *", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "estimatedSalesValue", label: "Quantity *", dataType: DealRegFormFieldDataType.Number, values: [] },
        { name: "estimatedCloseDate", label: "(Estimated) Invoice Date *", dataType: DealRegFormFieldDataType.Date, values: [] },
        { name: "invoice", label: "Customer Invoice (won deals only)", dataType: DealRegFormFieldDataType.File, values: [] }
      ],
      dealRegFormInitialValues: {
        status: 'OPEN',
        manufacturer: 'NONE',
        customerName: '',
        productType: '',
        opportunityDetails: '',
        estimatedSalesValue: 0,
        estimatedCloseDate: new Date()
      },
      dealsEditable: false
    }]
  }
}